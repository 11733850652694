
h3 {
	font-size: 25px !important;
	margin: 0 0 5px 0 !important;
	display: flex;
	justify-content: space-between;
	border-bottom: solid 1px #b2b2b2;
}

.equipment {
	display: grid;
	grid-template-rows: 60px 1fr;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 10px;

	.gold {
		grid-column: span 3;
	}
}
.new-item {
	min-width: 350px;

	.hk-card {
		height: 100%;
	}
}
.ac_wrapper {
	position: relative;
	width: 30px;
	height: 32px;

	i,
	.ac {
		position: absolute;
		line-height: 32px;
		text-align: center;
		right: 0;
		top: 0;
		width: 30px;
	}
	i {
		font-size: 30px;
		color: #5c5757;
	}
	.ac {
		font-weight: bold;
		color: #fff;
		margin-top: -1px;
	}
}
